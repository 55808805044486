import mindwaveDrone from "../../../../src/blog/2019-06-08 - Mindcraft/assets/mindwave-drone.jpg";
import mindwaveTutorial from "../../../../src/blog/2019-06-08 - Mindcraft/assets/mindwave-tutorial.png";
import mindcraftSteering from "../../../../src/blog/2019-06-08 - Mindcraft/assets/mindcraft-steering.png";
import mindcraftTraining from "../../../../src/blog/2019-06-08 - Mindcraft/assets/mindcraft-training.png";
import thotconMindwave from "../../../../src/blog/2019-06-08 - Mindcraft/assets/thotcon-mindwave.png";
import mindwaveFlight from "../../../../src/blog/2019-06-08 - Mindcraft/assets/mindwave-flight.gif";
import unboxingOpenBciGif from "../../../../src/blog/2019-06-08 - Mindcraft/assets/openbci-unboxing.gif";
import fullDemo from "../../../../src/blog/2019-06-08 - Mindcraft/assets/full-demo.jpg";
import backOfOpenBci from "../../../../src/blog/2019-06-08 - Mindcraft/assets/back-of-openbci.jpg";
import beginningOpenBci from "../../../../src/blog/2019-06-08 - Mindcraft/assets/openbci-beginning.jpg";
import completedOpenBci from "../../../../src/blog/2019-06-08 - Mindcraft/assets/openbci-completed.jpg";
import thotconOpenBciSetup from "../../../../src/blog/2019-06-08 - Mindcraft/assets/thotcon-openbci-setup.gif";
import motorCortex from "../../../../src/blog/2019-06-08 - Mindcraft/assets/motor-cortex.jpg";
import electrodePlacement from "../../../../src/blog/2019-06-08 - Mindcraft/assets/electrode-placement.png";
import motorImagery from "../../../../src/blog/2019-06-08 - Mindcraft/assets/motor-imagery.jpg";
import trainingData from "../../../../src/blog/2019-06-08 - Mindcraft/assets/training-data.png";
import tensorflowTraining from "../../../../src/blog/2019-06-08 - Mindcraft/assets/tensorflow-training.png";
import React from 'react';
export default {
  mindwaveDrone,
  mindwaveTutorial,
  mindcraftSteering,
  mindcraftTraining,
  thotconMindwave,
  mindwaveFlight,
  unboxingOpenBciGif,
  fullDemo,
  backOfOpenBci,
  beginningOpenBci,
  completedOpenBci,
  thotconOpenBciSetup,
  motorCortex,
  electrodePlacement,
  motorImagery,
  trainingData,
  tensorflowTraining,
  React
};